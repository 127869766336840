import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import SectionHeader from '../../../components/layout/header/SectionHeader';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  lastGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 20,
    },
  },
}));

const Customization = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        label={(
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            Contribute and get Lifetime Discount
          </Typography>
        )}
        title=""
        subtitle="Tell us actively about your E-Commerce experience with our system. Find Bugs, propose features,
         and get a lifetime discount. Ask us how!"
        align="center"
      />
    </div>
  );
};

export default Customization;
