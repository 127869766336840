import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  disableGutter: {
    marginBottom: 0,
  },
  title: {
    fontWeight: 'bold',
  },
  cta: {
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: theme.spacing(0),
    },
  },
}));

/**
 * Component to display the section headers
 *
 * @param {Object} props
 */
const SectionHeader = props => {
  const {
    title,
    titleVariant,
    subtitleVariant = 'h6',
    subtitle,
    subtitleColor,
    label,
    overline,
    fadeUp,
    align,
    ctaGroup,
    disableGutter,
    titleClasses,
    className,
    labelProps,
    titleProps,
    subtitleProps,
    ...rest
  } = props;

  const classes = useStyles();
  let justifyGrid = 'center';
  if (align === 'left') {
    justifyGrid = 'flex-start';
  } else if (align === 'right') {
    justifyGrid = 'flex-end';
  }

  return (
    <Grid
      container
      spacing={2}
      data-aos={fadeUp ? 'fade-up' : ''}
      className={clsx(
        classes.root,
        disableGutter ? classes.disableGutter : {},
        className,
      )}
      {...rest}
    >
      {overline && (
        <Grid
          item
          container
          justify={justifyGrid}
          xs={12}
        >
          {overline}
        </Grid>
      )}
      {label && (
        <Grid item xs={12}>
          {label}
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          variant={titleVariant}
          align={align || 'center'}
          className={clsx(
            'section-header__title',
            classes.title,
            titleClasses || {},
          )}
          color="textPrimary"
          {...titleProps}
          component="div"
        >
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          <Typography
            variant={subtitleVariant}
            align={align || 'center'}
            color={subtitleColor || 'textSecondary'}
            {...subtitleProps}
            component="div"
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
      {ctaGroup && ctaGroup.length && (
        <Grid item xs={12}>
          <Grid
            container
            justify={justifyGrid}
            alignItems="center"
            wrap="nowrap"
          >
            {ctaGroup.map((item, index) => (
              <div
                key={index}
                className={classes.cta}
              >
                {item}
              </div>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SectionHeader;
