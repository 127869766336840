import React from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery, Grid, Typography,
} from '@material-ui/core';
import Image from '../../../components/image/Image';
import SectionHeader from '../../../components/layout/header/SectionHeader';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const MainSection = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <SectionHeader
            title={(
              <span>
                Beautiful E-Commerce Website based on the
                <br />
                <Typography component="span" variant="inherit" color="primary">
                  Shopsuy System
                </Typography>
              </span>
            )}
            subtitle="Best practices in web development used by leading developers to make the best of a online shop"
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="/shops.png"
            alt="Online Shop"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainSection;
