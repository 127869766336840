import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider, Typography } from '@material-ui/core';
import Section from './Section';
import SectionAlternate from './SectionAlternate';
import Contact from './components/Contact';
import MainSection from './components/MainSection';
import Mobile from './components/Mobile';
import Facebook from './components/Facebook';
import Pricing from '../../components/displayData/Pricing';
import integrations from './integrations';
import Contribution from './components/Contribution';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.shape}>
        <Section className={classes.pagePaddingTop}>
          <Typography variant="h1">Online Shop System Philippines</Typography>
          <Typography variant="h2">Start in minutes with selling! Get now your personal E-Commerce System.</Typography>
        </Section>
        <Section className={classes.pagePaddingTop}>
          <MainSection />
          <br />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Mobile />
        </Section>
      </div>
      <Section>
        <Facebook data={integrations} />
      </Section>
      <SectionAlternate innerNarrowed>
        <Pricing />
      </SectionAlternate>
      <Divider />
      <SectionAlternate>
        <Contact />
      </SectionAlternate>
      <SectionAlternate>
        <Contribution />
      </SectionAlternate>
    </div>
  );
};

export default Home;
