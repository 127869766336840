import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

/**
 * Component to display the Count Up Numbers
 *
 * @param {Object} props
 */
const CountUpNumber = props => {
  const {
    start,
    end,
    suffix,
    prefix,
    label,
    textColor,
    labelColor,
    className,
    visibilitySensorProps,
    wrapperProps,
    countWrapperProps,
    countNumberProps,
    labelProps,
    ...rest
  } = props;

  const [viewPortEntered, setViewPortEntered] = React.useState(false);
  const setViewPortVisibility = isVisible => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <div className={clsx(className)} {...rest}>
      <VisibilitySensor
        onChange={isVisible => setViewPortVisibility(isVisible)}
        delayedCall
        {...visibilitySensorProps}
      >
        <div {...wrapperProps}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            align="center"
            color={textColor || 'textPrimary'}
            {...countWrapperProps}
          >
            <CountUp
              delay={1}
              redraw={false}
              end={end}
              start={start}
              suffix={suffix || ''}
              prefix={prefix || ''}
              {...countNumberProps}
            />
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            color={labelColor || 'textSecondary'}
            align="center"
            {...labelProps}
          >
            {label}
          </Typography>
        </div>
      </VisibilitySensor>
    </div>
  );
};

export default CountUpNumber;
