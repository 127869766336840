import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton,
} from '@material-ui/core';
import {
  Facebook, WhatsApp, Phone, Email,
} from '@material-ui/icons';
import { Element } from 'react-scroll';
import SectionHeader from '../../../components/layout/header/SectionHeader';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  lastGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '40%',
    },
  },
}));

const Contact = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div id="contact" className={clsx(classes.root, className)} {...rest}>
      <Element name="contact" />
      <SectionHeader
        label={<Typography variant="h3" style={{ textAlign: 'center' }}>Contact us and Get Started</Typography>}
        title="We are happy to answer all your question"
        subtitle="You can contact us by Messenger, Viber, WhatsApp, Phone or E-Mail"
        align="center"
        ctaGroup={[
          <a
            rel="noreferrer"
            href="https://www.facebook.com/Website-design-philippines-website-designph-536414820199009"
            target="_bank"
          >
            <IconButton aria-label="facebook">
              <Facebook fontSize="large" style={{ color: '#4267B2' }} />
            </IconButton>
          </a>,
          <a
            rel="noreferrer"
            href="https://wa.me/00359899117198"
            target="_bank"
          >
            <IconButton aria-label="whatsapp">
              <WhatsApp fontSize="large" style={{ color: '#075E54' }} />
            </IconButton>
          </a>,
          <a
            rel="noreferrer"
            href="https://www.facebook.com/messages/t/536414820199009"
            target="_bank"
          >
            <IconButton aria-label="messenger">
              <img alt="messenger" src="/messenger-logo.svg" width="35" />
            </IconButton>
          </a>,

        ]}
      />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography
          variant="body2"
          style={{
            display: 'flex', fontWeight: 'bold', alignItems: 'center', color: '#4E5A6A',
          }}
        >
          <Phone style={{ marginRight: 10 }} /> +359 899 117 198
        </Typography>
        <Typography
          variant="body2"
          style={{
            display: 'flex',
            fontWeight: 'bold',
            marginLeft: 10,
            alignItems: 'center',
            color: '#4E5A6A',
          }}
        >
          <Email style={{ marginRight: 10 }} /> philippines.website@gmail.com
        </Typography><br />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography
          variant="body2"
          style={{
            display: 'flex', fontWeight: 'bold', alignItems: 'center', marginTop: 10, color: '#4E5A6A',
          }}
        >
          Fiesta Comm. 17, Matatalaib
        </Typography>
        <Typography
          variant="body2"
          style={{
            display: 'flex', fontWeight: 'bold', alignItems: 'center', marginTop: 10, color: '#4E5A6A',
          }}
        >
          , 2300, Philippines, Tarlac
        </Typography>
      </div>
    </div>
  );
};

export default Contact;
