import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  useMediaQuery, Grid, Typography, Button,
} from '@material-ui/core';
import { Element, Link } from 'react-scroll';
import SectionHeader from '../layout/header/SectionHeader';
import Icon from '../buttons/Icon';
import CardPricingStandard from './CardPricingStandard';

const useStyles = makeStyles(() => ({
  root: {},
  fontWeight900: {
    fontWeight: 900,
  },
}));

const Pricing = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div id="pricing" className={clsx(classes.root, className)} {...rest}>
      <Element name="prices" />
      <SectionHeader
        title={<Typography variant="h3">Prices</Typography>}
        subtitle="Choose the best solution"
        data-aos="fade-up"
      />
      {
        /* ctaGroup={[
        <LearnMoreLink title="See what's included" href="#" variant="h6" />,
        ]} */
      }
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            withShadow
            liftUp
            title="Standard Shop"
            subtitle="Start selling in minutes"
            priceComponent={(
              <div>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Regular Price ₱ 1399
                </Typography>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                  style={{ color: '#dc2f32' }}
                >
                  ₱ 699
                  <Typography variant="caption" style={{ fontSize: '1.2rem', color: '#4E5A6A' }}> / Mo.</Typography>
                </Typography>
              </div>
            )}
            features={[
              'Responsive online shop',
              'Ready to use theme',
              'Customizable colors, fonts, widgets',
              'Installable as APP',
              'Mobile-First optimized',
              'Including Webhosting',
            ]}
            featureCheckComponent={(
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            )}
            cta={(
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                component={Link}
                to="contact"
                duration={300}
                spy
                smooth
              >
                GET IT NOW
              </Button>
            )}
            disclaimer="The first 30 days free!"
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title="Reseller"
            liftUp
            subtitle="Get monthly revenue from your customers"
            priceComponent={(
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  On Request
                </Typography>
              </div>
            )}
            features={[
              'We take care of the software',
              'You focus only on your marketing',
              'Flexible partner programs',
              'We actively develop new features',
              'Any Integration possible',
            ]}
            featureCheckComponent={(
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            )}
            cta={(
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                size="large"
                component={Link}
                to="contact"
                duration={300}
                spy
                smooth
              >
                Ask us now
              </Button>
            )}
            disclaimer="Just sell!"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Pricing;
