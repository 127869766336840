import React, { useState } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {
  AppBar, Paper, Toolbar, IconButton,
  Typography, Button, Drawer, List,
  Avatar, Hidden, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import {
  Menu as MenuIcon, Home as HomeIcon, Payment as PaymentIcon, ContactMail,
} from '@material-ui/icons';
import {
  Link, Element,
} from 'react-scroll';
import myTheme from './components/layout/theme';
import Home from './screens/home/Home';
import './App.css';

import 'react-lazy-load-image-component/src/effects/opacity.css';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'white',
  },
  list: {
    width: 250,
  },
  justSellLogo: {
    backgroundColor: 'white',
    marginRight: 15,
    padding: 8,
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ScrollLink = ({ to, children }) => (
  <Button
    color="inherit"
    component={Link}
    activeClass="active"
    to={to}
    spy
    smooth
    duration={300}
    aria-label={`Menu Button ${to}`}
  >
    {children}
  </Button>
);

function App() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState();

  return (
    <ThemeProvider theme={myTheme}>
      <AppBar position="sticky">
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Avatar
            alt="logo"
            src="https://just-sell.online/just-sell-online.png"
            className={classes.justSellLogo}
          />
          <Typography variant="h6" className={classes.title}>
            JUST-SELL with Your E-Commerce System
          </Typography>
          <Hidden smDown>
            <ScrollLink to="home">Home</ScrollLink>
            <ScrollLink to="prices">Prices</ScrollLink>
            <ScrollLink to="contact">Contact</ScrollLink>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <List className={classes.list}>
            {[
              { text: 'Home', Icon: HomeIcon, to: 'home' },
              { text: 'Prices', Icon: PaymentIcon, to: 'prices' },
              { text: 'Contact', Icon: ContactMail, to: 'contact' },
            ].map(({ Icon, text, to }) => (
              <ListItem
                button
                key={text}
                component={Link}
                activeClass="active"
                to={to}
                spy
                smooth
                duration={300}
                aria-label={`Link Button ${to}`}
              >
                <ListItemIcon><Icon /></ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden>
      <Paper>
        <Element name="home" />
        <Home />
      </Paper>
    </ThemeProvider>
  );
}

export default App;
