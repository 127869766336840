import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Image from '../../../components/image/Image';
import SectionHeader from '../../../components/layout/header/SectionHeader';
import CountUpNumber from '../../../components/displayData/CountUpNumber';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  placementGrid: {
    display: 'flex',
  },
  placementGridItemMiddle: {
    margin: `0 ${theme.spacing(3)}px`,
  },
  coverImage: {
    borderRadius: theme.spacing(2),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Mobile = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div {...rest}>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="/mobile.png"
            alt="..."
            className={classes.coverImage}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader
                title={(
                  <span>
                    Optimized for Mobile Phones
                    <br />
                    <Typography component="span" variant="inherit" color="primary">
                      Shop and APP in ONE
                    </Typography>
                  </span>
                )}
                subtitle="Your customers can use Shop on mobile phones, or Install shop as APP on the Phone.
                They can use Your Shop even Offline! Do you know that more than 90% of the customers you get
                trough Facebook will use mobile phones?"
                align="left"
                fadeUp
                disableGutter
                titleVariant="h3"
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.placementGrid}>
                <div>
                  <CountUpNumber
                    end={100}
                    label="WEB APP Performance"
                    textColor="primary"
                    suffix="%"
                    labelProps={{
                      style: { color: '#4E5A6A' },
                    }}
                  />
                </div>
                <div className={classes.placementGridItemMiddle}>
                  <CountUpNumber
                    end={100}
                    label="Mobile"
                    textColor="primary"
                    suffix="%"
                    labelProps={{
                      style: { color: '#4E5A6A' },
                    }}
                  />
                </div>
                <div>
                  <CountUpNumber
                    end={100}
                    label="Best Usability"
                    textColor="primary"
                    suffix="%"
                    labelProps={{
                      style: { color: '#4E5A6A' },
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Mobile;
