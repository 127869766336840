import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import Image from '../../../components/image/Image';
import SectionHeader from '../../../components/layout/header/SectionHeader';
import LearnMoreLink from '../../../components/buttons/LearnMoreLink';

const useStyles = makeStyles(() => ({
  root: {},
  logo: {
    maxWidth: 50,
  },
  coverImage: {
    height: 'auto',
  },
}));

const Facebook = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <SectionHeader
            title="Feed automatically your Facebook Catalogue"
            subtitle={(
              <>
                Automated fill for your{' '}
                <a
                  style={{ border: 'none', textDecoration: 'none', color: '#3f51b5' }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/business/learn/lessons/manage-inventory-with-catalogs"
                >
                  Facebook Catalog
                </a><br />
                Just setup your Facebook Catalogue and enter the feed link.
                Your Facebook Shop will pull every day the newest items you uploaded in your Online Shop.
              </>
)}
            align="left"
            label={<Typography variant="h3">Facebook Shop Integration</Typography>}
            ctaGroup={[
              <LearnMoreLink
                title="See how it works"
                href="https://www.facebook.com/business/help/125074381480892?id=725943027795860"
                variant="h6"
                target="_blank"
              />,
            ]}
            disableGutter
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="/facebook-shop.png"
            alt="facebook shop"
            className={classes.coverImage}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Facebook;
